import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader2 = _resolveComponent("BaseHeader2")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!
  const _component_BaseLine = _resolveComponent("BaseLine")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseHeader2, null, {
      "slot-middle": _withCtx(() => [
        _createTextVNode("알림 설정")
      ]),
      _: 1
    }),
    _createVNode(_component_List, null, {
      default: _withCtx(() => [
        _createVNode(_component_ListItem, null, {
          left: _withCtx(() => [
            _createTextVNode("활동 알림 받기")
          ]),
          right: _withCtx(() => [
            _createVNode(_component_Switch, {
              modelValue: _ctx.activity,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activity) = $event)),
              onClick: _ctx.toggleActivityAgree
            }, null, 8, ["modelValue", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_ListItem, null, {
          left: _withCtx(() => [
            _createTextVNode("키워드 알림 받기")
          ]),
          right: _withCtx(() => [
            _createVNode(_component_Switch, {
              modelValue: _ctx.keyword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.keyword) = $event)),
              onClick: _ctx.toggleKeywordAgree
            }, null, 8, ["modelValue", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_ListItem, null, {
          left: _withCtx(() => [
            _createTextVNode("마케팅 수신 동의")
          ]),
          right: _withCtx(() => [
            _createVNode(_component_Switch, {
              modelValue: _ctx.marketing,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.marketing) = $event)),
              onClick: _ctx.toggleMarketingAgree
            }, null, 8, ["modelValue", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_BaseLine, { color: 'var(--grey2-color)' }, null, 8, ["color"])
  ]))
}