import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("div", null, [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "left", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ]))
}