
import { defineComponent } from "vue";
import Switch from "@/components/common/Switch.vue";
import List from "@/components/alerts/settings/List.vue";
import ListItem from "@/components/alerts/settings/ListItem.vue";
import BaseLine from "@/components/common/BaseLine.vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";

export default defineComponent({
  name: "AlertSetting",
  components: { BaseHeader2, List, ListItem, Switch, BaseLine },
  data() {
    return {
      activity: false,
      keyword: false,
      marketing: false,
    };
  },
  methods: {
    loadDeviceInfo(): void {
      this.$axios
        .get("/device/mobile")
        .then((res) => {
          this.activity = res.data.activity_agree;
          this.keyword = res.data.keyword_agree;
          if (res.data.member) {
            this.marketing = res.data.member.agree3;
          }
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
    toggleKeywordAgree() {
      this.$axios
        .post("/device/mobile/keyword/agree")
        .then((res) => {
          this.keyword = res.data.keyword_agree;
          if (this.keyword) {
            this.$pushGAEvent("alert_on", {
              alert_type: "keyword",
            });
          } else {
            this.$pushGAEvent("alert_off", {
              alert_type: "keyword",
            });
          }
          const msg = "변경되었습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
    toggleActivityAgree() {
      this.$axios
        .post("/device/mobile/activity/agree")
        .then((res) => {
          this.activity = res.data.activity_agree;
          if (this.activity) {
            this.$pushGAEvent("alert_on", {
              alert_type: "activity",
            });
          } else {
            this.$pushGAEvent("alert_off", {
              alert_type: "activity",
            });
          }
          const msg = "변경되었습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
    toggleMarketingAgree(): void {
      this.$axios
        .post("/device/mobile/marketing/agree")
        .then((res) => {
          this.marketing = res.data.marketing_agree;
          const template =
            "영상인 마케팅 수신 {}하였습니다.\n" +
            new Date().toISOString().slice(0, 10).replaceAll("-", ".");
          const msg = this.marketing
            ? template.replace("{}", "동의")
            : template.replace("{}", "철회");

          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
  },
  beforeMount() {
    this.loadDeviceInfo();
  },
});
